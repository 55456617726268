/* body {
  font-family: "Gilroy", "Courier New", monospace, sans-serif;
} */

a {
  text-decoration: none !important;
}

.hero {
  margin-top: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) !important;
}

/* .row {
  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ) !important;
} */

.image {
  height: 100%;
  width: 100%;
}

nav {
  height: 80px;
  display: flex;
  padding: 20px 36px;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
  margin: 0px auto;
}

.nav--logo {
  max-width: 100px;
}

.navbar-nav li a:hover {
  color: #070861;
  border-bottom: 2px solid #070861;
  transition: all 0.5s ease-in;
  padding: 10px 0;
}

/* Main CSS */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

html,
body {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
  font-family: "Trebuchet MS", "Arial", "Helvetica", "sans-serif";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}
.header {
  font-size: 25px;
  color: #001e4f;
}

.maincontent {
  padding-bottom: 0px;
}

.captionlabel {
  font-weight: bold;
}

.biginput {
  font-weight: bold;
  font-size: 150%;
}

.notification {
  font-weight: bold;
  font-size: 120%;
}

.curvedinput {
  border: thin solid #c0c0c0;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 0;
}

.asterisk-danger {
  color: red;
  font-weight: bold;
  padding-left: 1px;
}

.optioncheckbox label {
  margin-right: 5px !important;
}

.optioncheckbox input[type="radio"] {
  margin-right: 5px !important;
}

.optioncheckbox input[type="checkbox"] {
  margin-right: 5px !important;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.exam-number .btn-group .btn {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.toast-message {
  position: fixed;
  top: 45%;
  width: 70%;
  z-index: 9999;
}

.img-box {
  margin: 0 20px 0 0;
  height: 20px;
  width: auto;
}

h1 {
  font-size: 45px;
  line-height: 1em;
  color: #353535;
  letter-spacing: -2px;
}

h2 {
  font-size: 37px;
  line-height: 1em;
  color: #27365e;
}

h2 span {
  display: block;
  font-size: 25px;
  color: #27365e;
  line-height: 1.2em;
  margin-top: -8px;
}

h3 {
  font-size: 24px;
  line-height: 1em;
  color: #27365e;
  margin-bottom: 1em;
}

h4 {
  font-size: 20px;
  line-height: 1em;
  color: #779395;
  margin-bottom: 1em;
}

h5 {
  font-size: 1em;
  color: #43a1d7;
  margin-bottom: 0.3em;
}

h6 {
  font-size: 20px;
  color: #3093c7;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
  word-wrap: break-word;
}

h2,
h3,
h4 {
  color: #27365e;
  text-align: center;
  font-weight: bolder;
}

.passportimage {
  border: medium solid #3399ff;
  width: 150px;
  height: 170px;
  margin-right: 20px;
  margin-bottom: 0px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.carousel-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

.slide-item {
  height: 550px;
}

.radiobuttonlist label {
  margin-right: 10px !important;
}

.radiobuttonlist input[type="radio"] {
  margin-right: 5px !important;
}

.radiobuttonlist input[type="checkbox"] {
  margin-right: 5px !important;
}

/* Big Buttons Styles*/
.bigbutton {
  padding: 5px;
  display: inline-block;
  height: 100px;
  font-size: 85%;
  font-weight: bold;
  white-space: normal !important;
  word-wrap: break-word;
  word-break: normal;
  margin-bottom: 5px;
}

.bigbutton:hover {
  cursor: pointer;
}

.wraptext {
  white-space: normal !important;
  word-wrap: break-word;
  word-break: normal;
}

.validator {
  color: #990000;
}

/* Toggle CheckBox */
.toggleCheckBox input[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 30px;
  -webkit-appearance: none;
  background: #dc3545;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.toggleCheckBox input:checked[type="checkbox"] {
  background: #28a745;
}

.toggleCheckBox input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.toggleCheckBox input:checked[type="checkbox"]:before {
  left: 30px;
}

/* Prefooter */
.prefooter {
  background: #6e787f;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.prefooter a {
  text-decoration: none;
  color: yellow;
}

.prefooter a:hover {
  text-decoration: underline;
  color: yellow;
  font-weight: bold;
}

/* Footer */
.footer {
  color: #6e787f;
  padding: 30px 0 30px 0;
  background: #000;
  margin-top: 0;
  font-size: 12px;
}

.footer a {
  text-decoration: none;
  color: #ae574f;
}

.footer a:hover {
  text-decoration: underline;
}

.dashboard .detail {
  font-size: 0.7em;
}

.dashboard .button {
  background-color: white;
}

.dashboard .button:hover {
  background-color: #007bff;
}

.dashboard .button:hover label {
  color: white;
}

.dashboard .button:hover .btn-primary {
  background-color: white !important;
  color: #007bff !important;
}

.dashboard .button:hover .fa {
  color: white !important;
}

.dashboard .button:hover .far {
  color: white !important;
}

.dashboard .button:hover .btn-link {
  color: white !important;
}

.ckcontent p{
  margin:0 !important;
}
/*Pagination style*/
.GridPager {
  /*display: inline-block;*/
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.GridPager table > tbody > tr > td {
  display: inline;
}

.GridPager table > tbody > tr > td > a,
.GridPager table > tbody > tr > td > span {
  position: relative;
  float: left;
  padding: 8px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #dd4814;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px;
}

.GridPager table > tbody > tr > td > span {
  position: relative;
  float: left;
  padding: 8px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  margin-left: -1px;
  z-index: 2;
  color: #aea79f;
  background-color: #f5f5f5;
  border-color: #dddddd;
  cursor: default;
}

.GridPager table > tbody > tr > td:first-child > a,
.GridPager table > tbody > tr > td:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.GridPager table > tbody > tr > td:last-child > a,
.GridPager table > tbody > tr > td:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.GridPager table > tbody > tr > td > a:hover,
.GridPager table > tbody > tr > td > span:hover,
.GridPager table > tbody > tr > td > a:focus,
.GridPager table > tbody > tr > td > span:focus {
  color: #97310e;
  background-color: #eeeeee;
  border-color: #dddddd;
}
/*Modify Bootstrap Toggle Handle*/
.toggle-handle {
  background-color: #fff;
  border-color: #fff;
}
/* Bootstrap Modification*/
.jumbotron {
  background-color: #27365e;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px 3px 0 0;
  margin: 0;
}

.row {
  margin-bottom: 5px;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.table-responsive {
  overflow: visible;
}

.table {
  display: block;
}

.table-no-space {
  padding: 0;
  margin: 0;
}

.table-no-space td,
.table-no-space th {
  padding: 5px;
  text-align: center;
  font-size: 80%;
}

.table-condensed td,
.table-condensed th {
  font-size: 50%;
}

.modalText {
  font-size: 1.5em;
  font-style: italic;
}

.modalValue {
  font-size: 1.5em;
  font-weight: bold;
  /*text-decoration: underline;*/
}

.modal-full {
  min-width: 98%;
  margin: 0 auto;
}

.datetime {
  font-size: 0.7em;
  color: #666;
}

.hideit {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

/*
*
* ===========================================================
*     POST SECTION
* ===========================================================
*
*/

.cardbox {
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0px !important;
}

/* ------------------------------- */
/* Cardbox Heading
---------------------------------- */
.cardbox .cardbox-heading {
  padding: 16px;
  margin: 0;
}

.cardbox .btn-flat.btn-flat-icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: #fff !important;
  background: #b5b6b6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cardbox .float-right .dropdown-menu {
  position: relative;
  left: 13px !important;
}

.cardbox .float-right a:hover {
  background: #f4f4f4 !important;
}

.cardbox .float-right a.dropdown-item {
  display: block;
  width: 100%;
  padding: 4px 0px 4px 10px;
  clear: both;
  font-weight: 400;
  font-family: "Abhaya Libre", serif;
  font-size: 14px !important;
  color: #848484;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
}

/* ------------------------------- */
/* Media Section
---------------------------------- */
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.media .mr-3 {
  margin-right: 1rem !important;
}

.media img {
  width: 48px !important;
  height: 48px !important;
  padding: 2px;
  border: 2px solid #f4f4f4;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
  padding: 0.4rem !important;
}

.media-body p {
  font-family: "Rokkitt", serif;
  font-weight: 500 !important;
  font-size: 14px;
  color: #88898a;
}

.media-body small span {
  font-family: "Rokkitt", serif;
  font-size: 12px;
  color: #aaa;
  margin-right: 10px;
}

/* ------------------------------- */
/* Cardbox Base
---------------------------------- */
cardbox-base {
  /*border-bottom: 2px solid #f4f4f4;
    border-top: 2px solid #f4f4f4;*/
}

.cardbox-base a {
  margin: 0px !important;
  padding: 0px !important;
}

.cardbox-base a i {
  color: #8d8d8d;
}

.cardbox-base a span {
  color: #8d8d8d;
}

.cardbox-base a img {
  width: 25px;
  height: 25px;
  margin: 0px !important;
  border: 2px solid #fff;
}

/* ------------------------------- */
/* Cardbox Comments
---------------------------------- */
.cardbox-comments {
  font-size: 0px;
  text-align: center;
  display: inline-block;
}

.cardbox-comments .comment-avatar img {
  margin-top: 1px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
}

.cardbox-comments .comment-body {
  overflow: auto;
}

.search {
  position: relative;
  right: -60px;
  top: -40px;
  bottom: -40px;
  border: 2px solid #f4f4f4;
  width: 100%;
  overflow: hidden;
}

.search input[type="text"] {
  background-color: #fff;
  /*line-height: 10px;
        padding: 15px 60px 20px 10px;*/
  border: none;
  border-radius: 4px;
  /*width: 350px;
        font-family: 'Rokkitt', serif;
        font-size: 14px;*/
  color: #8d8d8d;
  /*height: inherit;
        font-weight: 700;*/
}

.search button {
  position: absolute;
  right: 0;
  top: 0px;
  border: none;
  background-color: transparent;
  color: #bbbbbb;
  /*border: 15px 25px;*/
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.search button i {
  /*font-size20px;
            line-height: 30px;
            display: block;
        }*/
}

/* ------------------------------- */
/* Author
---------------------------------- */
.author a {
  font-family: "Rokkitt", serif;
  font-size: 16px;
  color: #00c4cf;
}

.author p {
  font-family: "Rokkitt", serif;
  font-size: 16px;
  color: #8d8d8d;
}
/* For Login */
.login .roundBorder {
  border-radius: 15px;
}

.loginBox.inputText {
  font-size: 15px;
  font-weight: 500;
  color: rgb(185, 184, 184);
}

.loginBox input {
  width: 100%;
}

.loginBox input {
  border: none;
  border-bottom: 1px solid #ccd1d7;
  background: transparent;
  outline: none;
  height: 40px;
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .break-line {
    display: block;
  }
}

/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
  .logo {
    width: 0px;
    height: 0px;
    visibility: collapse;
  }

  .header {
    font-size: 20px;
  }

  .btn-responsive {
    white-space: normal !important;
    word-wrap: break-word;
    word-break: normal;
  }
}

@media print {
  body {
    background-color: #fff !important;
  }

  .jumbotron,
  .header {
    background-color: #fff !important;
    color: #000 !important;
  }

  .footer,
  .prefooter {
    display: none !important;
  }

  .modalText {
    font-size: 2em !important;
  }

  .modalValue {
    font-size: 2em !important;
  }

  h2,
  h3,
  h4 {
    color: #000 !important;
  }
}
